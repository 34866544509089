import React from 'react'
import { Link } from 'gatsby'
import config from '../../../config'

const Footer = () => {
  return (
    <footer className='footer has-background-black'>


      <div className='container'>

        <div className='columns is-vcentered has-text-centered'>

          <div className='column is-size-2 has-text-weight-bold'>
            <Link className='has-text-grey' to='/blog'>
                            Breakthroughs
            </Link>
          </div>
          
          <div className='column is-size-2 has-text-weight-bold'>
            <Link className='has-text-grey' to='/faq'>
                            FAQs
            </Link>
          </div>

          <div className='column is-size-2 has-text-weight-bold'>
            <Link className='has-text-grey' to='/contact'>
                            Contact
            </Link>
          </div>

          <div className='column is-size-2 has-text-weight-bold'>
            <Link className='has-text-primary' to='/order'>
                            Book
            </Link>
          </div>

          <div className='column is-4 has-text-centered has-text-grey'>
            <p>
              {config.copyright} | <Link className='has-text-grey has-text-italic' to='/terms'>T&Cs</Link>
              <br />
              #10583363 Registered in England and Wales
            </p>
          </div>

        </div>
      </div>
    </footer>
  )
}

export default Footer
