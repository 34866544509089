import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'

const NavBar = ({ toggleNavbar, isActive }) => (
  <StaticQuery
    query={graphql`
            query SearchIndexQuery {
                siteSearchIndex {
                    index
                }
            }
        `}
    render={data => (
      <nav className='navbar is-spaced' aria-label='main navigation'>
            <div className='container'> 
              <div className='columns column is-10 is-offset-1'>
                <div className='navbar-brand'>
                  <Link to='/' className='navbar-item'>
                    <p className='logo'><strong className='has-text-black is-size-4 is-italic has-font-weight-bold'>BOLD <br />REWRITE</strong>
                    <br />
                    .com</p>
                  </Link>
                  <button
                    className={`button mr-3 mt-3 navbar-burger ${isActive ? 'is-active' : ''}`}
                    data-target='navMenu'
                    onClick={toggleNavbar}
                  >
                    <span />
                    <span />
                    <span />
                  </button>
                </div>
                <div className={`navbar-menu is-size-6 ${isActive ? 'is-active' : ''}`} id='navMenu'>

                  <div className='navbar-end'>
                    <Link className='navbar-item is-italic has-text-grey' to='/about'>
                                    Not convinced?
                    </Link>
                    <div className='navbar-item'>
                      <div className='field is-grouped'>
                        <p className='control'>
                          <Link
                            className='button is-primary'
                            to='/order'>
                                    Book Your Bold Rewrite
                          </Link>
                        </p>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
      </nav>
    )}
  />
)

export default NavBar
